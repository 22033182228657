@import '../node_modules/leaflet/dist/leaflet.css';
@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/rc-slider/assets/index.css';
@import '../node_modules/react-image-gallery/styles/css/image-gallery.css';

:root {
  --neutral-dark: #1a2030;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-feature-settings: 'lnum';
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
}

body,
button,
input {
  font-family: 'Open Sans', sans-serif;
  color: var(--neutral-dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Next Book', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 400;
}

b,
strong,
em,
i {
  font-style: normal;
}

a {
  text-decoration: none;
  color: #007ab8;
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

form {
  margin: 0;
  padding: 0;
}

.app {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 47.99em) {
  .app--noScrollBody {
    max-height: 100vh;
    overflow: hidden;
  }
}

.app--noScroll > .contentContainer {
  min-height: 0;
  max-height: 0;
}
.app--noScroll > .footer {
  min-height: 0;
  max-height: 0;
}
.app--noScroll > .header {
  min-height: 0;
  max-height: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.grecaptcha-badge {
  display: none;
}

/* Hide the scrollbar on all elements when printing */
@media only print {
  *::-webkit-scrollbar {
    width: 0 !important;
  }

  * {
    -ms-overflow-style: none;
  }
}

/* LEGAL DISCLAIMER
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.kvd.se
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Optimo webfonts are protected by copyright law and provided under license. To modify, alter, translate, convert, decode or reverse engineer in any manner whatsoever, including converting the Font Software into a different format is strictly prohibited. The webfont files are not to be used for anything other than web font use.
 *
 * optimo.ch
 */

@font-face {
  font-family: 'Next Book';
  src: url('/fonts/NEXT-Book/NEXT-Book-Bold.eot');
  src: url('/fonts/NEXT-Book/NEXT-Book-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/NEXT-Book/NEXT-Book-Bold.woff2') format('woff2'),
    url('/fonts/NEXT-Book/NEXT-Book-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  font-display: swap;
}

@font-face {
  font-family: 'Next Book';
  src: url('/fonts/NEXT-Book/NEXT-Book-Regular.eot');
  src: url('/fonts/NEXT-Book/NEXT-Book-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/NEXT-Book/NEXT-Book-Regular.woff2') format('woff2'),
    url('/fonts/NEXT-Book/NEXT-Book-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  font-display: swap;
}

/* Needed since react-collapse 5.0.0 - do not remove*/
.ReactCollapse--collapse {
  transition: height 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Background transition */
.backgroundTransition-enter {
  opacity: 0;
}

.backgroundTransition-enter.backgroundTransition-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.backgroundTransition-exit {
  opacity: 1;
}

.backgroundTransition-exit.backgroundTransition-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
